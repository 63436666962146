<template>
  <b-overlay :show="loading">
    <section class="new-loan-request-wrapper">
      <validation-observer ref="applyLoanForm">
        <b-form
          class="auth-register-form mt-2"
        >
        
          <b-row class="new-loan-request">
            <b-col
              cols="12"
              xl="10"
              md="10"
            >
              <b-card
                  no-body
                  class="invoice-preview-card"
                >
                  <b-card-body class="invoice-padding pt-0 mt-5">
                    <span class="font-weight-bold mb-1 d-block"><strong>Amount: </strong></span>
                    
                    <b-form-group
                      label="Amount"
                      label-for="number"
                    >
                      <cleave
                        id="number"
                        v-model="amountInput"
                        class="form-control w-20"
                        raw
                        :options="{
                          numeral: true,
                          numeralThousandsGroupStyle: 'thousand',
                          onValueChanged: onAmountChanged
                        }"
                      />
                    </b-form-group>

                    <div class="wrap">
                      <vue-slider
                        v-model="newLoan.amount"
                        :min="getValueFromSource(clientGroup, 'meta.minimum_amount', 0)"
                        :max="getValueFromSource(clientGroup, 'meta.maximum_amount', 0)"
                        :interval="1"
                        :contained="true"
                        tooltip="always"
                        direction="ltr"
                        class="mb-1"
                      />
                    </div>

                    <div class="d-flex justify-content-between">
                      <p class="font-weight-bold">
                        {{ formatMoney(getValueFromSource(clientGroup, 'meta.minimum_amount', 0), 0) }}
                      </p>
                      <p class="font-weight-bold">
                        {{ formatMoney(getValueFromSource(clientGroup, 'meta.maximum_amount', 0), 0) }}
                      </p>
                    </div>
                  </b-card-body>

                  <b-card-body class="invoice-padding pt-2">
                    <span class="font-weight-bold"><strong>Term: </strong></span>
                    <div class="demo-inline-spacing">
                      <b-form-radio
                        v-for="(loan_term, i) in getValueFromSource(clientGroup, 'loan_terms', [])"
                        :key="i"
                        v-model="newLoan.term_id"
                        :value="loan_term._id"
                        name="loan-term"
                      >
                        {{ loan_term.title }}
                      </b-form-radio>
                    </div>
                  </b-card-body>

                  <b-card-body class="invoice-padding pt-5">
                    <span class="font-weight-bold"><strong>Summary: </strong></span>
                    <hr class="invoice-spacing">
                  </b-card-body>

                  <b-card-body class="invoice-padding pt-0">
                    <b-row class="new-loan-request">
                      <b-col
                        cols="12"
                        xl="7"
                        md="9"
                        sm="12"
                      >
                        <div class="d-flex justify-content-between">
                          <p class="font-weight-bold">
                            Processing Fee
                          </p>

                          <p class="font-weight-bold">
                            {{ processingFee }}%
                          </p>
                        </div>

                        <div class="d-flex justify-content-between">
                          <p class="font-weight-bold">
                            Payout
                          </p>

                          <p class="font-weight-bold">
                            ¢{{ formatMoney(payout) }}
                          </p>
                        </div>

                        <div class="d-flex justify-content-between">
                          <p class="font-weight-bold">
                            Interest Rate
                          </p>

                          <p class="font-weight-bold">
                            {{ interestRate }}%
                          </p>
                        </div>

                        <div class="d-flex justify-content-between">
                          <p class="font-weight-bold">
                            Interest
                          </p>

                          <p class="font-weight-bold">
                          ¢{{ formatMoney(interest) }}
                          </p>
                        </div>

                        <div class="d-flex justify-content-between">
                          <p class="font-weight-bold">
                            Monthly Payment
                          </p>

                          <p class="font-weight-bold">
                            ¢{{ formatMoney(monthlyPayment) }}
                          </p>
                        </div>

                        <div class="d-flex justify-content-between mb-5">
                          <p class="font-weight-bold">
                            Total Loan Cost
                          </p>

                          <p class="font-weight-bold">
                            <strong>¢{{ formatMoney(totalLoanCost) }}</strong>
                          </p>
                        </div>

                        <!-- <pre>
                          {{
                            {
                              payout,
                              interest,
                              monthlyPayment,
                              totalLoanCost
                            }
                          }}
                        </pre> -->
                      </b-col>
                    </b-row>
                  </b-card-body>

                  <b-card-body class="invoice-padding pt-0">
                    <b-button
                      variant="primary"
                      class="mr-2"
                      :to="{ name: 'client-new-loan', params: { loan_product_id: clientGroup._id } }"
                    >
                      Request Loan
                    </b-button>
                  </b-card-body>
                </b-card>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </section>
  </b-overlay>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'

import {
  BOverlay, BRow, BCol, BCard, BCardBody, BButton, 
  BCardText, BFormTextarea, BFormCheckbox, BFormRadio, 
  BFormGroup, BPopover, VBToggle, BFormSpinbutton,
  BFormFile, BForm, BFormInput, BLink, BAlert,
} from 'bootstrap-vue'

import VueSlider from 'vue-slider-component'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

import { required } from '@validations'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BPopover,
    flatPickr,
    vSelect,
    BFormTextarea,
    BFormCheckbox,
    BFormRadio,
    BFormGroup,
    BFormSpinbutton,
    BFormFile,
    BForm,
    BFormInput,
    BLink,
    VueSlider,
    Cleave,
    BAlert,

    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],
  data(){
    return {
      loading: false,
      errorMessage: "",
      amountInput: "",
      newLoan: {
        amount: 0,
        term_id: '',

        // validation rules
        required,
      }
    }
  },
  computed: {
    userData(){
      return this.$store.getters[`auth/userData`];
    },
    clientGroup(){
      return this.$store.getters[`auth/clientGroup`];
    },
    settings(){
      return this.$store.getters[`auth/settings`];
    },
    termData(){
      const { term_id } = this.newLoan;
      const term_data = this.getValueFromSource(this.clientGroup, 'loan_terms', []).find(term => term._id === term_id);
      return term_data || {}
    },
    term(){
      return this.getValueFromSource(this.termData, 'number_of_months', 0);
    },
    amount(){
      return parseFloat(this.newLoan.amount) // .toFixed(2)
    },
    processingFee(){
      return this.getValueFromSource(this.settings, 'loan_processing_fee', 0)
    },
    payout(){
      return parseFloat(this.amount * (1 - (this.processingFee / 100))) // .toFixed(2);
    },
    interestRate(){
      const admin_rate = this.getValueFromSource(this.settings, 'loan_monthly_rate', 0)
      return admin_rate
    },
    interest(){
      const calcRate = this.interestRate * this.term
      return parseFloat(this.amount * (calcRate / 100)) // .toFixed(2);
    },
    monthlyPayment(){
      const calc = (+this.interest + +this.amount) / this.term
      return parseFloat(calc) // .toFixed();
    },
    totalLoanCost(){
      return parseFloat(+this.amount + +this.interest) // .toFixed(2)
    }
  },
  watch: {
    "newLoan.amount": {
      handler(amount){
        this.amountInput = amount
      }, 
      immediate: false
    }
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)

    this.newLoan.amount = this.getValueFromSource(this.clientGroup, 'meta.minimum_amount', 0)

    const term_id = this.getValueFromSource(this.clientGroup, 'loan_terms[0]._id', '');
    this.newLoan.term_id = term_id
  },
  mounted() {
    this.initTrHeight()
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    onAmountChanged(e){
      const { rawValue: amount } = e.target
      const client_group_minimum = this.getValueFromSource(this.clientGroup, 'meta.minimum_amount', 0)
      const client_group_maximum = this.getValueFromSource(this.clientGroup, 'meta.maximum_amount', 0)
      if (!amount || amount < client_group_minimum){
        // this.newLoan.amount = client_group_minimum
      } else if (!amount || amount > client_group_maximum){
        // this.newLoan.amount = client_group_maximum
      } else {
        // Only accept allowed values
        this.newLoan.amount = amount
      }
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        // this.trSetHeight(this.$refs.form.scrollHeight)
      })
    }
  },
  setup() {
    return {}
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-slider.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  @import "~@core/scss/base/pages/app-invoice.scss";
  @import '~@core/scss/base/components/variables-dark';

  .new-loan-request-wrapper {
    .add-new-client-header {
      padding: $options-padding-y $options-padding-x;
        color: $success;

      &:hover {
        background-color: rgba($success, 0.12);
      }
    }
  }

  .form-item-section {
  background-color:$product-details-bg;
  }

  .form-item-action-col {
    width: 27px;
  }

  .repeater-form {
    // overflow: hidden;
    transition: .35s height;
  }

  .v-select {
    &.item-selector-title,
    &.payment-selector {
      background-color: #fff;

      .dark-layout & {
        background-color: unset;
      }
    }
  }

  .dark-layout {
    .form-item-section {
      background-color: $theme-dark-body-bg;

      .row .border {
        background-color: $theme-dark-card-bg;
      }

    }
  }

  .wrap {
    border-left: 1px dashed #ddd;
    border-right: 1px dashed #ddd;
    padding: 10px 0;
  }

  .w-20 {
    width: 150px;
  }
</style>
